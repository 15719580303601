import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Link from "next/link";

const Menu = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (menuOpened) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpened]);

  return (
    <>
      <Ham onClick={() => setMenuOpened(!menuOpened)} menuOpened={menuOpened}>
        <span></span>
        {!menuOpened && <span></span>}
        <span></span>
      </Ham>
      <M opened={menuOpened}>
        <MenuContainer>
          {menuOpened && (
            <Men onClick={() => setMenuOpened(false)}>
              <h2>Menu</h2>
              <li>
                <Link href="https://monafoma.net.au/" passHref>
                  <a target="_blank">Program</a>
                </Link>
              </li>
              <li>
                <Link href="https://buy.monafoma.net.au" passHref>
                  <a target="_blank">Tickets</a>
                </Link>
              </li>
              <li>
                <Link href="https://monafoma.net.au/page/information" passHref>
                  <a target="_blank">Info</a>
                </Link>
              </li>
              <li>
                <Link href="https://monafoma.net.au/subscribe" passHref>
                  <a target="_blank">Subscribe</a>
                </Link>
              </li>
              <li>
                <Link href="https://monafoma.net.au/map" passHref>
                  <a target="_blank">Map</a>
                </Link>
              </li>
              <li>
                <Link href="https://monafoma.net.au/page/thank-you" passHref>
                  <a target="_blank">Partners</a>
                </Link>
              </li>
            </Men>
          )}
        </MenuContainer>
      </M>
    </>
  );
};

export default Menu;

const Ham = styled.button`
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 1002;
  cursor: pointer;
  background: transparent;
  height: 40px;
  width: 40px;
  border: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;

  span {
    height: 3px;
    width: 30px;
    display: inline-block;
    background: #000;
    transition: 0.2s ease-in-out all;
    top: 0;
  }

  span:first-child {
    transform-origin: 0% 0%;
    transform: ${(props) =>
      props.menuOpened && "rotate(45deg) translate(2px, -5px)"};
  }

  span:last-child {
    transform-origin: 0% 100%;
    transform: ${(props) =>
      props.menuOpened && "rotate(-45deg) translate(2px, 5px)"};
  }

  @media (min-width: 880px) {
    top: 15px;
    left: 15px;
  }
`;

const M = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.opened ? "100vh" : "0")};
  background: #ffd800;
  z-index: 998;
  overflow: hidden;

  @media (min-width: 880px) {
    width: 33%;
    mix-width: 300px;
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5vh;

  h2 {
    font-size: 2em;
  }

  @media (min-width: 880px) {
    padding-top: 10vh;

    h2 {
      font-size: 44px;
    }
  }
`;

const Men = styled.ul`
  list-style-type: none;
  text-align: center;
  padding: 0;

  li {
    margin: 0.5em auto;
    font-size: 1.5em;

    svg {
      height: 80px;
    }

    a {
      text-decoration: underline;
      :hover {
        text-decoration: line-through;
      }
    }
  }
`;
