import BuyButton from "./BuyButton"
import styled from 'styled-components'

const Description = () => (
  <D>
    <BuyButton id={0} buttonText={"BUY NOW"} noBorder />
    <p>
      Launce$ton and Hobart—south of the mainl*nd but secretly on top of the
      style/gorp globe—deliver the summer’s best <strong>SZEX</strong>ssesories,{" "}
      <strong>HEAVY FIRE</strong> facewear in the form of sunglasses that
      protect you from both UV <i>and </i>making eye contact with the
      unfortunate swagless peons who missed out on these puppies: the undeniable
      Bruny Island ferry of summer fits.
    </p>
    <BuyButton id={1} buttonText={"YOU HAD ME AT LAUNCE$TON"} noBorder />
    <p>
      They were borne of the kind of collab-spiratorial{" "}
      <strong>FEVER DREAM</strong> we mere mortals can only dream of, when
      professional surfer and dedicated <strong>AMATEUR CROWDSURFER</strong>{" "}
      Dion Agius approached Brian Ritchie (the guy that played bass on Blister
      in the Sun; also festival director of a little-understood but{" "}
      <strong>SIGNIFICANTLY-SLAPPING</strong> summer soiree called Mona Foma)
      with a shady scheme. Dion had a dream to switch up Brian’s trademark
      spenny foreign sunglasses for <strong>SPECCY SPECS</strong> designed right
      here in Tasmania. Brian jumped at the opportunity of creating a{" "}
      <strong>SENSE OF PLACE ON HIS FACE</strong> and the Mona Foma x EPØKHE
      sunnies were born.&nbsp;
    </p>
    <BuyButton
      id={2}
      buttonText={"TOO EXCITED TO READ, READY TO COP SHADES"}
      noBorder
    />
    <p>
      Based upon Dion’s very own signature <strong>SZEX</strong> frames, they’re
      crafted in eco-friendly bio-acetate and feature{" "}
      <strong>CUSTOM-GRADIENT POLARISED</strong> lenses engineered to make
      everything look like <strong>PURE BLISS</strong>. A righteous customised{" "}
      <strong>TECHNICOLOUR-EXPLOSION</strong> case and cleaning cloth complete
      the suite. These puppies are limited edition (expect{" "}
      <strong>RAPID SCARCITY</strong>), but boast unlimited recognition (of your
      undeniable street cred).&nbsp;
    </p>
    <BuyButton id={3} buttonText={"HYPEBEASTS, ASSEMBLE"} noBorder />
    <p>
      But wait! There’s more! <strong>NOT MERELY MERCH</strong>, these
      sunglasses are also a ticket to a <strong>VERY SPECIAL, VERY COOL</strong>{" "}
      experience at Mona Foma 2022. These off-rose-coloured peeper-protectors
      will admit the head and body attached to them into a{" "}
      <strong>POLARISING</strong> festival event somewhere in Hobart that only
      Mona Foma x EPØKHE wearers can see.
    </p>
    <BuyButton id={4} buttonText={"GIVE US MONEY, RECEIVE SHADES"} noBorder />
    <p>
      Buy these sunnies, look extra cool this post-apocalyptic summer and see
      the world (and the festival) through <strong>FRESH</strong> eyes indeed.
    </p>
    <BuyButton
      id={5}
      buttonText={"FEEL-GOOD FIT OF THE SUMMER? YES PLEASE"}
      noBorder
    />
    <p>
      <strong>Frame</strong>
    </p>
    <ul>
      <li> Engineered in Italy</li>
      <li> Thick shark fin temple</li>
      <li> Custom front and temple detailing</li>
      <li>
        {" "}
        Made from hand-shaped bio-acetate produced from natural cellulose
      </li>
    </ul>
    <br />
    <p>
      <strong>Lens</strong>
      <br />
    </p>
    <ul>
      <li> Polarised</li>
      <li> 100% UV protection</li>
      <li> Scratch resistant</li>
      <li> All EPØKHE frames are RX compatible</li>
    </ul>
  </D>
);

export default Description

const D = styled.div`
  table {
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }
  th,
  td {
    border-bottom: 1px solid #000;
  }

  td {
    text-align: right;
  }
`;