import { useEffect } from 'react'

let BuyButton = ({
  id = "1633584053125",
  buttonAlign = "center",
  buttonText = "Add to cart",
  noBorder = false,
}) => {
  useEffect(() => {
    (function () {
      var scriptURL =
        "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement("script");
        script.async = true;
        script.src = scriptURL;
        (
          document.getElementsByTagName("head")[0] ||
          document.getElementsByTagName("body")[0]
        ).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = ShopifyBuy.buildClient({
          domain: "mona-sh-p.myshopify.com",
          storefrontAccessToken: "ef90783b4a63b9e7cfdc9b9a57976238",
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent("product", {
            id: "7154449416354",
            node: document.getElementById(`product-component-${id}`),
            moneyFormat: "%24%7B%7Bamount%7D%7D",
            options: {
              product: {
                width: noBorder ? "100%" : "auto",
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-bottom": "50px",
                    },
                    "text-align": `${buttonAlign}`,
                  },
                  title: {
                    "font-family": "Roboto, sans-serif",
                  },

                  button: {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    "font-size": "14px",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                    color: "#FFD800",
                    ":hover": {
                      color: "#000000",
                      "background-color": "#e6c200",
                    },
                    "background-color": "#000",
                    border: noBorder ? "none" : "1px solid #000",
                    ":focus": {
                      "background-color": "#e6c200",
                    },
                    "border-radius": "0px",
                    width: noBorder ? "100%" : "auto",
                    "text-align": "center",
                  },
                  quantityInput: {
                    "font-size": "14px",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                  },
                  price: {
                    "font-family": "Roboto, sans-serif",
                  },
                  compareAt: {
                    "font-family": "Roboto, sans-serif",
                  },
                  unitPrice: {
                    "font-family": "Roboto, sans-serif",
                  },
                },
                contents: {
                  img: false,
                  title: false,
                  price: false,
                },
                text: {
                  button: `${buttonText} ${noBorder ? ">" : ""}`,
                },
                googleFonts: ["Roboto"],
              },
              productSet: {
                styles: {
                  products: {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px",
                    },
                  },
                },
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px",
                    },
                  },
                  button: {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    "font-size": "14px",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                    color: "#000000",
                    ":hover": {
                      color: "#000000",
                      "background-color": "#e6c200",
                    },
                    "background-color": "#ffd800",
                    ":focus": {
                      "background-color": "#e6c200",
                    },
                    "border-radius": "0px",
                  },
                  quantityInput: {
                    "font-size": "14px",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                  },
                  title: {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "bold",
                    "font-size": "26px",
                    color: "#4c4c4c",
                  },
                  price: {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "18px",
                    color: "#4c4c4c",
                  },
                  compareAt: {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "15.299999999999999px",
                    color: "#4c4c4c",
                  },
                  unitPrice: {
                    "font-family": "Helvetica Neue, sans-serif",
                    "font-weight": "normal",
                    "font-size": "15.299999999999999px",
                    color: "#4c4c4c",
                  },
                },
                googleFonts: ["Roboto"],
                text: {
                  button: "Add to cart",
                },
              },
              option: {
                styles: {
                  label: {
                    "font-family": "Roboto, sans-serif",
                  },
                  select: {
                    "font-family": "Roboto, sans-serif",
                  },
                },
                googleFonts: ["Roboto"],
              },
              cart: {
                styles: {
                  button: {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    "font-size": "14px",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                    color: "#000000",
                    ":hover": {
                      color: "#000000",
                      "background-color": "#e6c200",
                    },
                    "background-color": "#ffd800",
                    ":focus": {
                      "background-color": "#e6c200",
                    },
                    "border-radius": "0px",
                  },
                },
                text: {
                  total: "Subtotal",
                  button: "Checkout",
                },
                popup: false,
                googleFonts: ["Roboto"],
              },
              toggle: {
                styles: {
                  toggle: {
                    "font-family": "Roboto, sans-serif",
                    "font-weight": "bold",
                    "background-color": "#ffd800",
                    ":hover": {
                      "background-color": "#e6c200",
                    },
                    ":focus": {
                      "background-color": "#e6c200",
                    },
                  },
                  count: {
                    "font-size": "14px",
                    color: "#000000",
                    ":hover": {
                      color: "#000000",
                    },
                  },
                  iconPath: {
                    fill: "#000000",
                  },
                },
                googleFonts: ["Roboto"],
              },
            },
          });
        });
      }
    })();
  }, []);
  return (
    <div
      id={`product-component-${id}`}
      style={{ minHeight: "70px", marginBottom: "2em" }}
    ></div>
  );
};

export default BuyButton