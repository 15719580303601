import Head from "next/head";
import Description from "../components/Description";
import Logo from "../components/Logo";
import Client from "shopify-buy";

import { useEffect, useState, useRef } from "react";

import styled from "styled-components";
import Menu from "../components/Menu";

function Home(props) {
  const [slideshowNum, setSlideshowNum] = useState(0);
  const [switchImage, setSwitchImage] = useState(false);

  return (
    <div className="container">
      <Head>
        <title>MONA FOMA x EPØKHE LIMITED EDITION SUNNIES</title>
        <meta
          name="title"
          content="MONA FOMA x EPØKHE LIMITED EDITION SUNNIES"
        />
        <meta name="description" content="Designed in Tasmania. Shop now." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://epokhe.monafoma.net.au/" />
        <meta
          property="og:title"
          content="MONA FOMA x EPØKHE LIMITED EDITION SUNNIES"
        />
        <meta
          property="og:description"
          content="Designed in Tasmania. Shop now."
        />
        <meta
          property="og:image"
          content="https://mofo2022.s3.ap-southeast-2.amazonaws.com/epokhe/mona-epokhe-05.jpeg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://epokhe.monafoma.net.au/"
        />
        <meta
          property="twitter:title"
          content="MONA FOMA x EPØKHE LIMITED EDITION SUNNIES"
        />
        <meta
          property="twitter:description"
          content="Designed in Tasmania. Shop now."
        />
        <meta
          property="twitter:image"
          content="https://mofo2022.s3.ap-southeast-2.amazonaws.com/epokhe/mona-epokhe-05.jpeg"
        />
      </Head>
      <Texture
        bgImage={
          switchImage
            ? "https://mofo2022.s3.ap-southeast-2.amazonaws.com/overlays/mf22-bg-NEW-5.png"
            : "https://mofo2022.s3.ap-southeast-2.amazonaws.com/overlays/mf22-bg-NEW-5.png"
        }
      />
      <Menu />
      <main>
        <div>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <ImageContainer>
            <MainImage src={props.img[slideshowNum].src} />
            <OtherImages>
              {props.img
                .filter((v, i) => i !== slideshowNum)
                .map((img, i) => {
                  return (
                    <Img
                      src={img.src}
                      key={img.src}
                      onClick={() => {
                        setSlideshowNum(img.imageNum);
                      }}
                      style={{ alignSelf: "start" }}
                    />
                  );
                })}
            </OtherImages>
          </ImageContainer>

          <PTB>
            <PTD>
              <PriceTitle>
                <h1>{props.title}</h1>
                <h3>{props.price}</h3>
              </PriceTitle>
              <AfterPay>
                or make 4 interest-free payments of <b>$62.50</b> AUD
                fortnightly with{" "}
                <a
                  href="https://www.afterpay.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="https://mofo2022.s3.ap-southeast-2.amazonaws.com/epokhe/after-pay.png" />
                </a>
                &nbsp;
                <a
                  href="https://www.afterpay.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  more info
                </a>
              </AfterPay>
              <Description />
            </PTD>
          </PTB>
        </div>
      </main>

      <style jsx global>{`
        html,
        body {
          padding: 0;
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
            sans-serif;

          background: #ffd800;
          background: linear-gradient(#54cdd4, #ffd800, #b9d56c);
        }

        main {
          position: relative;
          width: 800px;
          max-width: 100%;
          padding: 0px 10px;
          padding-bottom: 40px;
          background: #ffd800;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
        }

        * {
          box-sizing: border-box;
        }

        @media (min-width: 666px) {
          main {
            padding: 50px 25px;
            padding-top: 0;
            margin: 40px auto;
          }
        }
      `}</style>
    </div>
  );
}

export default Home;

export async function getStaticProps({ params }) {
  const client = Client.buildClient({
    domain: "mona-sh-p.myshopify.com",
    storefrontAccessToken: "ef90783b4a63b9e7cfdc9b9a57976238",
  });

  let productDetails = await client.product
    .fetchByHandle("pre-order-sunnies-mona-foma-x-epokhe")
    .then((pro) => {
      let img = pro.images.map((im, i) => {
        return {
          src: im.src,
          imageNum: i,
        };
      });

      return {
        description: pro.description,
        title: pro.title,
        img: img,
        price: pro.variants[0].price,
        dHtml: pro.descriptionHtml,
      };
    });

  return {
    props: { ...productDetails },
    // Return 404 page if content not found
    notFound: !productDetails,
  };
}

const Img = styled.img``;

const Texture = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url(${(props) =>
    props.bgImage ? props.bgImage : "chippies-bg.jpg"});
  background-position: top center;

  img {
    width: 100%;
    height: 100%;
  }
`;

const AfterPay = styled.div`
  font-size: 0.8em;
  margin-bottom: 25px;
  padding-top: 5px;

  img {
    max-height: 1em;
  }

  a {
    text-decoration: underline;
  }
`;

const PriceTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  align-items: center;

  h1,
  h3 {
    margin-bottom: 10px;
  }

  @media (min-width: 666px) {
    flex-wrap: nowrap;

    h1,
    h3 {
      margin-bottom: 0px;
    }

    h1 {
      font-size: 18px;
    }
  }
`;

const PTD = styled.div`
  width: 100%;

  @media (min-width: 666px) {
    width: 100%;
    flex-shrink: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`;

const PTB = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 666px) {
    flex-wrap: nowrap;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  flex-wrap: wrap;

  @media (min-width: 666px) {
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
`;
const MainImage = styled.img`
  width: 80%;
  align-self: flex-start;
  margin-bottom: 15px;

  @media (min-width: 666px) {
    margin-right: 15px;
  }
`;
const OtherImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: calc(25% - 16px);
    margin: 0px 8px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  img:hover {
    border: 1px solid black;
  }

  @media (min-width: 666px) {
    flex-direction: column;

    img {
      max-height: 139px;
      margin: 0;
      margin-bottom: 15px;
      width: auto;
    }
  }
`;

const C = styled.canvas`
  position: fixed;
  left: 0;
  top: 0;
`;

const LogoContainer = styled.div`
  padding: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 400px;
    width: 100%;
  }
`;
